/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaCalculator, FaHome } from "react-icons/fa";
import { RotatingLines } from "react-loader-spinner";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  Hero,
  Menu,
  NavbarContainer,
  NavbarContent,
  PageTitle,
  ResultTitle,
  Tabelas,
} from "./styles";

import logoImg from "../../assets/images/site/logo.png";

export function Simulador() {
  const [showCards, setShowCards] = useState(false);
  const [dados, setDados] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  function clearFaturamento() {
    setLoading(false);
    setDados(false);
    setShowCards(false);
  }

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const navigateTolead = () => {
    location.href = `https://marketing.easydr.com.br/simulador-de-impostos-easy-dr`;
  };

  const query = useQuery();

  if (query.get("unlock") === "true") {
    sessionStorage.setItem("coins-calculadora", Number.MAX_SAFE_INTEGER);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ faturamento }) => {
    setLoading(true);
    if (sessionStorage.getItem("coins-calculadora") > 0) {
      // dados a serem enviados pela solicitação POST
      const payload = {
        valor: faturamento,
      };

      fetch(
        "https://rpc.easydr.com.br/api-operacional/parametrizacao/",
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: { "Content-type": "application/json; charset=UTF-8" },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setDados(data);
        })
        .catch((err) => console.log(err));
    } else {
      setShow(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dados.faturamento) {
      setShowCards(true);
    }
  }, [dados]);

  const validate = {
    required: { value: true, message: "Preenchimento obrigatório!" },
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const formatPercentage = (value) => {
    return value.toFixed(2) + '%';
  }; 
  
  return (
    <>
      <NavbarContainer>
        <NavbarContent>
          <Link to="/">
            <img src={logoImg} alt="Logo EasyDr." width={70} />
          </Link>
          {dados ? (
            <Menu>
              <Button onClick={() => clearFaturamento()}>
                <FaCalculator size={20} />
                Simular Novamente
              </Button>
            </Menu>
          ) : (
            <Menu>
              <Link to="/">
                <Button>
                  <FaHome size={20} />
                  Página Principal
                </Button>
              </Link>
            </Menu>
          )}
        </NavbarContent>
      </NavbarContainer>
      <main>
        <Hero>
          <div className="container">
            <Card>
              {!showCards ? (
                <div className="card-body">
                  <PageTitle>
                    Verifique qual a melhor opção para você.
                  </PageTitle>
                  <div className="d-flex justify-content-center">
                    <form
                      className="row g-3 d-flex justify-content-center"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="col-12">
                        <label htmlFor="valor" className="form-label">
                          Informe o Faturamento/Salário Bruto mensal:
                        </label>

                        <input
                          type="number"
                          min="0"
                          step="0.01"
                          autoFocus
                          {...register("faturamento", validate)}
                          className={`form-control ${
                            errors.faturamento ? "is-invalid" : ""
                          }`}
                          id="valor"
                        />
                        <div className="invalid-feedback">
                          {errors?.faturamento?.message}
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary">
                        {loading ? (
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "5px",
                            }}
                          >
                            <RotatingLines width="20" strokeColor="white" />{" "}
                            Carregando
                          </span>
                        ) : (
                          <span>Realizar Cálculos</span>
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              ) : (
                <Tabelas>
                  <ResultTitle>Resultado da simulação</ResultTitle>
                  <div className="row">
                    <div className="col-sm-4 col-xl-4 col-md-6 mb-4">
                      <div className="card border-0 h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                Faturamento
                              </div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">
                                {formatCurrency(dados.faturamento)}
                              </div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4 col-xl-4 col-md-6 mb-4">
                      <div className="card border-0 h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                Economia Mensal
                              </div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">
                                {formatCurrency(dados.economiaMensal)}
                              </div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4 col-xl-4 col-md-12 mb-4">
                      <div className="card border-0 h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                Economia Anual
                              </div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">
                                {formatCurrency(dados.economiaAnual)}
                              </div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <ul className="price">
                      <li className="header">Pessoa Física</li>
                      <li>
                        <table className="table">
                          <tbody>
                            <tr>
                              <th style={{ textAlign: "left" }}>Tributos</th>
                              <th>Alíquota</th>
                              <th>Valor Mensal</th>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "left" }}>INSS:</td>
                              <td>{formatPercentage(dados.aliquotaPfInss)}</td>
                              <td>{formatCurrency(dados.valorMensalPfInss)}</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "left" }}>IRPF:</td>
                              <td>{formatPercentage(dados.aliquotaPfIrpf)}</td>
                              <td>{formatCurrency(dados.ValorMensalIRPF_PF)}</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td />
                            </tr>
                            <tr style={{ verticalAlign: "middle" }}>
                              <td style={{ textAlign: "left" }}>
                                Alíquota Efetiva:
                              </td>
                              <td>{formatPercentage(dados.AliquotaEfetiva_PF)}</td>
                              <td>{formatCurrency(dados.ValorMensalAliquotaEfetiva_PF)}</td>
                            </tr>
                            <tr>
                              <th colSpan="2" style={{ textAlign: "left" }}>
                                Total mensal
                              </th>
                              <td>{formatCurrency(dados.totalMensalPF)}</td>
                            </tr>
                            <tr>
                              <th colSpan="2" style={{ textAlign: "left" }}>
                                {" "}
                                Total anual
                              </th>
                              <td>{formatCurrency(dados.totalAnualPF)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                    </ul>
                  </div>

                  <div className="columns">
                    <ul className="price">
                      <li className="header">Simples Anexo III</li>
                      <li>
                        <table className="table">
                          <tbody>
                            <tr>
                              <th style={{ textAlign: "left" }}>Tributos</th>
                              <th>Alíquota</th>
                              <th>Valor Mensal</th>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                Alíquota Efetiva:
                              </td>
                              <td>
                                {formatPercentage(dados.aliquotaINSS_III.porcentagem)}
                              </td>
                              <td>{formatCurrency(dados.aliquotaINSS_III.mensal)}</td>
                            </tr>
                            <tr>
                              <th colSpan="2" style={{ textAlign: "left" }}>
                                Total líquido
                              </th>
                              <td>{formatCurrency(dados.totalMensalSimplesAnexoIII)}</td>
                            </tr>
                            <tr>
                              <th colSpan="2" style={{ textAlign: "left" }}>
                                {" "}
                                Anual líquido
                              </th>
                              <td>{formatCurrency(dados.totalAnualSimplesAnexoIII)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                    </ul>
                  </div>

                  <div className="columns">
                    <ul className="price">
                      <li className="header">Lucro Presumido</li>
                      <li>
                        <table className="table">
                          <tbody>
                            <tr>
                              <th style={{ textAlign: "left" }}>Tributos</th>
                              <th>Alíquota</th>
                              <th>Valor Mensal</th>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                Alíquota Efetiva:
                              </td>
                              <td>{formatPercentage(dados.ale_LP)}</td>
                              <td>{formatCurrency(dados.aleMensal_LP)}</td>
                            </tr>
                            <tr>
                              <th colSpan="2" style={{ textAlign: "left" }}>
                                Total líquido
                              </th>
                              <td>{formatCurrency(dados.totalM_LP)}</td>
                            </tr>
                            <tr>
                              <th colSpan="2" style={{ textAlign: "left" }}>
                                {" "}
                                Anual líquido
                              </th>
                              <td>{formatCurrency(dados.totalA_LP)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                    </ul>
                  </div>
                </Tabelas>
              )}
            </Card>
          </div>
        </Hero>
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Continue simulando</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Preencha seus dados e libere seu acesso a novas simulações!
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShow(false)}>
              Fechar
            </Button>
            <Button variant="primary" onClick={() => navigateTolead()}>
              Preencher o formulário
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    </>
  );
}
